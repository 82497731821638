import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "deploy-ocelot"
    }}>{`Deploy Ocelot`}</h1>
    <h2 {...{
      "id": "run-on-kubernetes"
    }}>{`Run on Kubernetes`}</h2>
    <p>{`In order to run Ocelot in Kubernetes we are going to use some standard components as follow:`}</p>
    <ul>
      <li parentName="ul">{`A Service Account to provide an identity for the Ocelot process;`}</li>
      <li parentName="ul">{`A deployment for the main middleware container;`}</li>
      <li parentName="ul">{`2 services (one for the BFF, needed if you will use the Ocelot Web Application, and one for the middleware);`}</li>
      <li parentName="ul">{`An Horizontal Pod Autoscaler to handle load dynamically;`}</li>
    </ul>
    <p>{`Some of these components can be used as-is across different providers, some of them insead need specific configuration
based on the Cloud Vendor you trust to run your Kubernetes workloads.`}</p>
    <p>{`In the following we published the yaml files used across vendors. Then afterwards you will be able to check how to
complete Ocelot deployment based on your vendor.`}</p>
    <p>
Before we dive into the technical details we would like to point out that most of the configuration variables used in
the following sections are explained in much more details in the <Link to="/src-markdowns-5-1-configuration" mdxType="Link">configuration section</Link>
    </p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: v1
kind: Service
metadata:
  labels:
    app: ocelot
  name: ocelot
  namespace: security
spec:
  ports:
    - name: http
      port: 8080
      targetPort: 8080
  selector:
    app: ocelot
---
apiVersion: v1
kind: Service
metadata:
  labels:
    app: ocelot
  name: ocelot-bff
  namespace: security
spec:
  type: LoadBalancer
  ports:
    - name: http
      port: 8080
      targetPort: 8080
  selector:
    app: ocelot
---
apiVersion: autoscaling/v2beta2
kind: HorizontalPodAutoscaler
metadata:
  name: hpa-ocelot
  namespace: security
spec:
  scaleTargetRef:
    apiVersion: apps/v1
    kind: Deployment
    name: ocelot
  minReplicas: 1
  maxReplicas: 3
  metrics:
    - type: Resource
      resource:
        name: cpu
        target:
          type: Utilization
          averageUtilization: 70
    - type: Resource
      resource:
        name: memory
        target:
          type: Utilization
          averageUtilization: 70
`}</code></pre>
    <h2 {...{
      "id": "run-on-google-kubernetes-engine-gke"
    }}>{`Run on Google Kubernetes Engine (GKE)`}</h2>
    <p>{`We can run Ocelot on GCP against PostgreSQL, in which case the parts related to the DB will be similar to the example
above. In the following we will instead go through the configuration of Ocelot using Firestore.`}</p>
    <p>{`We advise you to use `}<a parentName="p" {...{
        "href": "https://cloud.google.com/kubernetes-engine/docs/how-to/workload-identity"
      }}>{`GKE Workload Identity`}</a>{`
and in general to follow `}<a parentName="p" {...{
        "href": "https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster"
      }}>{`security best practices`}</a>{`
advised by Google.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: v1
kind: ServiceAccount
metadata:
  name: ocelot
  namespace: security
  annotations:
    iam.gke.io/gcp-service-account: ocelot-prod@<PROJECT_NAME>.iam.gserviceaccount.com
---
apiVersion: v1
kind: ConfigMap
metadata:
  name: ocelot
  namespace: security
data:
  SPRING_PROFILES_ACTIVE: firestore,gcp
  CORS_ORIGINS: <ORIGINS>
  COOKIE_MAX_AGE_SEC: "<MAX_AGE_SECS>"
---
apiVersion: apps/v1
kind: Deployment
metadata:
  name: ocelot
  namespace: security
  labels:
    app: ocelot
spec:
  replicas: 1
  strategy:
    type: RollingUpdate
    rollingUpdate:
      maxSurge: 1
      maxUnavailable: 0
  selector:
    matchLabels:
      app: ocelot
  template:
    metadata:
      labels:
        app: ocelot
    spec:
      serviceAccountName: ocelot
      nodeSelector:
        iam.gke.io/gke-metadata-server-enabled: true
      containers:
        - name: ocelot
          image: <ARTIFACT_REGISTRY>/yanchware/ocelot/ocelot:v2.3.2
          imagePullPolicy: IfNotPresent
          resources:
            requests:
              cpu: 200m
              memory: 300Mi
            limits:
              cpu: 1000m
              memory: 600Mi
          env:
            - name: _JAVA_OPTIONS
              value: "-Xms250m -Xmx2560m"
            - name: COOKIE_MAX_AGE_SEC
              valueFrom:
                configMapKeyRef:
                  name: ocelot
                  key: COOKIE_MAX_AGE_SEC
            - name: SPRING_PROFILES_ACTIVE
              valueFrom:
                configMapKeyRef:
                  name: ocelot
                  key: SPRING_PROFILES_ACTIVE
            - name: CORS_ORIGINS
              valueFrom:
                configMapKeyRef:
                  name: ocelot
                  key: CORS_ORIGINS
`}</code></pre>
    <h2 {...{
      "id": "run-on-azure-kubernetes-services-aks"
    }}>{`Run on Azure Kubernetes Services (AKS)`}</h2>
    <p>{`In the following configuration we have used `}<inlineCode parentName="p">{`Pod-Managed Identities`}</inlineCode>{`. You can follow these links if you would like to
read more about `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/aks/operator-best-practices-identity"
      }}>{`best-practices for security`}</a>{`
advised by Microsoft and specifically about `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/aks/use-azure-ad-pod-identity"
      }}>{`Pod-Managed Identities`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: v1
kind: ServiceAccount
metadata:
  name: ocelot
  namespace: security
---
apiVersion: v1
kind: ConfigMap
metadata:
  name: ocelot
  namespace: security
data:
  SPRING_PROFILES_ACTIVE: postgres,azure
  CORS_ORIGINS: <ORIGINS>
  COOKIE_MAX_AGE_SEC: "<MAX_AGE_SECS>"
  TENANT_ID: <TENANT_ID>
  KEY_VAULT_SUBSCRIPTION_ID: <KEY_VAULT_SUBSCRIPTION_ID>
  KEY_VAULT_NAME: <KEY_VAULT_NAME>
  KEY_VAULT_RG_NAME: <KEY_VAULT_RESOURCE_GROUP_NAME>
---
apiVersion: apps/v1
kind: Deployment
metadata:
  name: ocelot
  namespace: security
  labels:
    app: ocelot
    aadpodidbinding: mi-ocelot-prod
spec:
  replicas: 1
  strategy:
    type: RollingUpdate
    rollingUpdate:
      maxSurge: 1
      maxUnavailable: 0
  selector:
    matchLabels:
      app: ocelot
  template:
    metadata:
      labels:
        app: ocelot
    spec:
      serviceAccountName: ocelot
      containers:
        - name: ocelot
          image: <ACR_ADDRESS>/yanchware/ocelot/ocelot:v2.3.2
          imagePullPolicy: IfNotPresent
          resources:
            requests:
              cpu: 200m
              memory: 300Mi
            limits:
              cpu: 1000m
              memory: 600Mi
          env:
            - name: _JAVA_OPTIONS
              value: "-Xms250m -Xmx2560m"
            - name: COOKIE_MAX_AGE_SEC
              valueFrom:
                configMapKeyRef:
                  name: ocelot
                  key: COOKIE_MAX_AGE_SEC
            - name: SPRING_PROFILES_ACTIVE
              valueFrom:
                configMapKeyRef:
                  name: ocelot
                  key: SPRING_PROFILES_ACTIVE
            - name: CORS_ORIGINS
              valueFrom:
                configMapKeyRef:
                  name: ocelot
                  key: CORS_ORIGINS
            - name: TENANT_ID
              valueFrom:
                configMapKeyRef:
                  name: ocelot
                  key: TENANT_ID
            - name: KEY_VAULT_SUBSCRIPTION_ID
              valueFrom:
                configMapKeyRef:
                  name: ocelot
                  key: KEY_VAULT_SUBSCRIPTION_ID
            - name: KEY_VAULT_NAME
              valueFrom:
                configMapKeyRef:
                  name: ocelot
                  key: KEY_VAULT_NAME
            - name: KEY_VAULT_RG_NAME
              valueFrom:
                configMapKeyRef:
                  name: ocelot
                  key: KEY_VAULT_RG_NAME
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      